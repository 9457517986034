import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function FilterDialog(prop) {
    const { action } = prop;

    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState(null);
    const [distancia, setDistancia] = React.useState(localStorage.getItem("filterDistancia"));
    const [checked, setChecked] = React.useState({
        dentro: localStorage.getItem('filterCheckedDentro') == "true" ? true : false,
        fora: localStorage.getItem('filterCheckedFora') == "true" ? true : false,
        show: localStorage.getItem('filterCheckedShow') == "true" ? true : false,
    });

    const handleLimpaFiltro = () => {
        if (action) action(null);
        setDate(null);
        setOpen(false);
    }
    const handleAplicarFiltro = () => {
        const form = {
            data: date,
            distancia: document.getElementById("distancia").value,
            dentro: document.getElementById("dentro").checked,
            fora: document.getElementById("fora").checked,
            show: document.getElementById("show").checked
        }
        localStorage.setItem("filterDistancia", distancia)
        localStorage.setItem("filterCheckedDentro", checked.dentro)
        localStorage.setItem("filterCheckedFora", checked.fora)
        localStorage.setItem("filterCheckedShow", checked.show)
        if (action) action(form);
        setOpen(false)
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleFiltro = () => {
        console.log(">>handleFiltro<<")
        setOpen(true);
    }
    const handleChangeCheckbox = (e) => {
        setChecked({
            ...checked,
            [e.target.id]: e.target.checked
        })
    }

    return (
        <React.Fragment>
            <div className="topcornerFilter">
                <IconButton aria-label="filter" onClick={handleFiltro} color="secondary">
                    <SearchIcon />
                </IconButton>
            </div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Filtro dos dispositivos
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Stack spacing={2}>

                        <DatePicker label="Ultima visualização" format="DD/MM/YYYY" value={date} onChange={(value) => setDate(value)} />
                        <TextField
                            id="distancia"
                            label="Distância do ponto de referencia"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={distancia || ""}
                            onChange={(e) => setDistancia(e.target.value)}
                        />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox id="dentro" onChange={handleChangeCheckbox} checked={(checked && checked.dentro) ? true : false} />} label="Dentro da distância" />
                            <FormControlLabel control={<Checkbox id="fora" onChange={handleChangeCheckbox} checked={(checked && checked.fora) ? true : false} />} label="Fora da distância" />
                            <FormControlLabel control={<Checkbox id="show" onChange={handleChangeCheckbox} checked={(checked && checked.show) ? true : false} />} label="Apresentar a distancia visualmente" />
                        </FormGroup>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleLimpaFiltro}>
                        Limpa Filtro
                    </Button>
                    <Button autoFocus onClick={handleAplicarFiltro}>
                        Aplicar Filtro
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
