import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import Service from '../services/Service';
import { Button, CardActions, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from '@mui/material';
import FilterDialog from './FilterDialog';

let DefaultIcon = L.icon({
    iconUrl: 'roomIconMapaAzul.png',
    iconAnchor: [19, 46],
    popupAnchor: [-6, -47],

});


// let referencia = { lat: -22.8910135, lng: -47.1258095 }
let coord = [];
function Mapa() {
    const inputEl = useRef(null);

    const [devices, setDevices] = useState(null);
    const [devicesFiltrado, setDevicesFiltrado] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState();
    const [raioCircle, setRaioCircle] = useState();

    useEffect(() => {
        buscaSetting();
        const dist = localStorage.getItem("filterDistancia");
        if (dist > 0) {
            setRaioCircle(dist)
        }
    }, []);

    function buscaSetting() {
        Service.GET('/api/setting')
            .then(r => {
                if (!r || !r.pontoReferencia) return;
                coord = ([r.pontoReferencia.latitude, r.pontoReferencia.longitude])
                buscaDispositivos();
            });
    }
    function buscaDispositivos() {
        Service.GET('/api/device/findAll')
            .then(r => {
                for (let x in r) {
                    r[x].distancia = getDistanceFromLatLonInMetros(r[x].latitude, r[x].longitude)
                }
                setDevices(r);
                let arry = [];
                const dist = localStorage.getItem("filterDistancia");
                for (let x in r) {
                    if (dist) {
                        let distancia = r[x].distancia;
                        if (localStorage.getItem('filterCheckedFora') == "true") {
                            if (dist * 1000 <= distancia) {
                                arry.push(r[x]);
                            }
                        }
                        if (localStorage.getItem('filterCheckedDentro') == "true") {
                            if (dist * 1000 > distancia) {
                                arry.push(r[x]);
                            }
                        }
                    } else {
                        arry.push(r[x]);
                    }
                }
                setDevicesFiltrado(arry);
            });
    }

    function formatarData(d) {
        const opt = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };
        return (new Date(d)).toLocaleString('pt-BR', opt);
    }

    function getDistanceFromLatLonInMetros(lat, lng) {
        if (!lat || !lng) return '';
        var deg2rad = function (deg) { return deg * (Math.PI / 180); },
            R = 6371,
            dLat = deg2rad(coord[0] - lat),
            dLng = deg2rad(coord[1] - lng),
            a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
                + Math.cos(deg2rad(lat))
                * Math.cos(deg2rad(lat))
                * Math.sin(dLng / 2) * Math.sin(dLng / 2),
            c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return ((R * c * 1000).toFixed());
    }

    function openDialog(d) {
        // if(inputEl.current.leafletElement)
        // inputEl.current.leafletElement.closePopup();
        setDialogInfo(d);
        // setOpen(true);
    }
    const aplicaFiltro = (e) => {
        let arr = [];
        if (e && e.data) {
            let dt1 = new Date(e.data)
            for (let x in devices) {
                if (dt1) {
                    let dt2 = new Date(devices[x].lastSeenDate);
                    if (dt1 < dt2) {
                        arr.push(devices[x]);
                    }
                }
            }
            setDevicesFiltrado(arr)
        }
        if (e && e.distancia) {
            for (let x in devices) {
                if (e.distancia) {
                    let distancia = devices[x].distancia;
                    if (e.fora) {
                        if (e.distancia * 1000 <= distancia) {
                            arr.push(devices[x]);
                        }
                    } if (e.dentro) {
                        if (e.distancia * 1000 > distancia) {
                            arr.push(devices[x]);
                        }
                    }
                }
            }
            setDevicesFiltrado(arr);
            setRaioCircle(e.distancia);
        } else {
            setRaioCircle();
        }
        if (!arr || arr.length === 0) {
            setDevicesFiltrado(devices);
        }
    }

    function buscarIcone(e) {


        //coordenada centro de campinas
        let latitudeCampinas = -22.9055;
        let longitudeCampinas = -47.0659;

        var radlat1 = Math.PI * latitudeCampinas / 180;
        var radlat2 = Math.PI * e.latitude / 180;
        var theta = longitudeCampinas - e.longitude;
        var radtheta = Math.PI * theta / 180;

        var distancia = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        distancia = Math.acos(distancia);
        distancia = distancia * 180 / Math.PI;
        distancia = distancia * 60 * 1.1515; // Em milhas
        distancia = distancia * 1.609344; // Em quilômetros

        let icone = 'roomIconMapaAzul.png'

        if(distancia > 10){

            icone = 'roomIconMapaVermelho.png'
        }


        let iconFiltrado = L.icon({
            iconUrl: icone,
            iconAnchor: [19, 46],
            popupAnchor: [-6, -47],

        });

        return iconFiltrado;


    }

    return (
        <React.Fragment>
            {devices &&
                <MapContainer
                    ref={inputEl} style={{
                        position: 'absolute',
                        height: '80%',
                        width: '100%'
                    }}
                    center={[coord[0], coord[1]]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {(localStorage.getItem('filterCheckedShow') == "true" && raioCircle && coord[0]) &&
                        <Circle
                            center={{ lat: coord[0], lng: coord[1] }}
                            fillColor="blue"
                            radius={raioCircle ? (raioCircle * 1000) : 100} />
                    }

                    {devicesFiltrado && devicesFiltrado.map((disp, index) => (
                        <Marker key={index} position={[disp.latitude, disp.longitude]} icon={buscarIcone(disp)}>
                            <Popup>
                                Usuário: {disp.deviceName} <br />
                                Modelo do dispositivo: {disp.model}  <br />
                                Ultima atualização: {formatarData(disp.lastSeenDate)} <br />
                                Distancia: {disp.distancia}<br />
                                <CardActions sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    // 👇 Edit padding to further adjust position
                                    p: 0,
                                }} >
                                    <Button onClick={() => openDialog(disp)} >Mais</Button>
                                </CardActions>
                            </Popup>
                        </Marker>
                    ))}

                </MapContainer>}
            <Dialog
                open={open}
                onClose={() => setOpen(false)} >
                <DialogTitle id="alert-dialog-title">
                    Informações {dialogInfo && dialogInfo.deviceName}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText > */}
                    <Typography gutterBottom >
                        ID: {dialogInfo && dialogInfo.id}
                    </Typography>
                    <Typography gutterBottom >
                        deviceName: {dialogInfo && dialogInfo.deviceName}
                    </Typography>
                    <Typography gutterBottom >
                        Modelo do dispositivo: {dialogInfo && dialogInfo.model}
                    </Typography>
                    <Typography gutterBottom >
                        description: {dialogInfo && dialogInfo.description}
                    </Typography>
                    <Typography gutterBottom >
                        os: {dialogInfo && dialogInfo.os}
                    </Typography>
                    <Typography gutterBottom >
                        groupName: {dialogInfo && dialogInfo.groupName}
                    </Typography>
                    <Typography gutterBottom >
                        managed: {dialogInfo && dialogInfo.managed}
                    </Typography>
                    <Typography gutterBottom >
                        managementType: {dialogInfo && dialogInfo.managementType}
                    </Typography>
                    <Typography gutterBottom >
                        location: {dialogInfo && dialogInfo.location}
                    </Typography>
                    <Typography gutterBottom >
                        Ultima atualização: {dialogInfo && formatarData(dialogInfo.lastSeenDate)}
                    </Typography>
                    <Typography gutterBottom >
                        Distancia: {dialogInfo && getDistanceFromLatLonInMetros(dialogInfo.latitude, dialogInfo.longitude)} <br />
                    </Typography>
                    {/* </DialogContentText> */}
                    <DialogActions >
                        <Button onClick={() => setOpen(false)} >Fechar</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <FilterDialog action={aplicaFiltro} />
        </React.Fragment>
    )
}

export default Mapa;