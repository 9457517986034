import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';

export default function MainAppBar() {
    const navigate = useNavigate();

    React.useEffect(() => {
        //if (localStorage.getItem("secretPassword") !== "qV6)f7+7sq_9") {
        if (!localStorage.getItem("secretPassword") ) {
            navigate("signIn")
        }
    }, []);
    return (
        <React.Fragment>
            <Header />
            <Outlet />
        </React.Fragment>
    );
}
