import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import Paper from '@mui/material/Paper';
import Service from '../services/Service';
import TablePaginationActions from './util/TablePaginationActions';
import SearchField from './util/SearchField';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function DispositivoTable() {

    const [devices, setDevices] = React.useState(null);
    const [devicesFiltrado, setDevicesFiltrado] = React.useState(null);
    const [row, setRow] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        buscaDispositivos();
    }, [])


    function buscaDispositivos() {
        Service.GET('/api/device/findAll')
            .then(r => {
                console.log(r)
                setDevices(r);
                setDevicesFiltrado(r)
                setRow(r.slice(0, rowsPerPage) )
            });
    }
    function formataData(sdt) {
        if (!sdt) return "";
        return new Date(sdt).toLocaleString();
    }
    const handleChangePage = (event, newPage) => {
        if (newPage === 0) {
            const arr = devicesFiltrado.slice(0, rowsPerPage);
            setRow(arr);
        } else {
            const arr = devicesFiltrado.slice(newPage * rowsPerPage, (1 + newPage) * rowsPerPage);
            setRow(arr);
        }
        setPage(newPage);

    };
    const handleChangeRowsPerPage = (event) => {
        if (event.target.value === -1) {
            if (devicesFiltrado.length > 100) {
                alert("Maximo da pagina é 100");
                const arr = devicesFiltrado.slice(0, 100);
                setRow(arr);
                setRowsPerPage(100);
                setPage(0);
            }
        }
        const arr = devicesFiltrado.slice(0, event.target.value);
        setRow(arr);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleFiltro = (txt)=>{
        console.log( txt );
      }
      const handleFindOnPress = (txt)=> {
        let arr = [];
        for(let x in devices){
            const item = devices[x];
            for(let h in item){
                if( item[h] && (item[h]+"").includes( txt) ){
                    arr.push( item );
                    break;
                }
            }
        }
        setDevicesFiltrado(arr);
        arr = arr.slice(0, rowsPerPage);
        
        setRow( arr );
        setPage(0);
      }
    return (
        <TableContainer component={Paper} style={{marginTop:'40px'}}>
            <SearchField 
                doAction={handleFiltro}
                findOnPress={handleFindOnPress}
            />
            <Table sx={{ minWidth: 700 }} aria-label="customized table" style={{marginTop:'20px'}}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Dispositivo</StyledTableCell>
                        <StyledTableCell>Grupo</StyledTableCell>
                        <StyledTableCell>Modelo</StyledTableCell>
                        <StyledTableCell>OS</StyledTableCell>
                        <StyledTableCell>Data da ultima vista</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {row && row.map((row) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell>{row.deviceName}</StyledTableCell>
                            <StyledTableCell>{row.groupName}</StyledTableCell>
                            <StyledTableCell>{row.model}</StyledTableCell>
                            <StyledTableCell>{row.os}</StyledTableCell>
                            <StyledTableCell>{formataData(row.lastSeenDate)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>

                <TableFooter>
          <TableRow>
            {devicesFiltrado && 
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              labelRowsPerPage={"Linhas por pagina"}
              colSpan={5}
              count={devicesFiltrado.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'Linhas por pagina',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
            }
          </TableRow>
        </TableFooter>
            </Table>
        </TableContainer>
    );
}
