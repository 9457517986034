
import * as React from "react";
import {
  Route,
  Link,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import './App.css';
import Mapa from './components/Mapa';
import MainAppBar from "./components/MainAppBar";
import Settings from "./components/Setings";
import SignIn from "./components/SignIn";
import DispositivoTable from "./components/DispositivoTable";

function App({ children }) {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
          <Route path="/" element={<MainAppBar />}>
            <Route path="" element={<div><Mapa /></div>} />
            <Route path="home" element={<div><Mapa /></div>} />
            <Route path="dispositivos" element={<DispositivoTable/>} />

            <Route path="settings" element={<Settings />} />

            <Route path="*" element={<NoMatch />} />
          </Route>
          <Route path="signIn" element={<SignIn />} />
        </Routes>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;


function NoMatch() {
  return (
    <div>
      <h2>Pagina não encontrada</h2>
      <p>
        <Link to="/">volte para a página inicial</Link>
      </p>
    </div>
  );
}